
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'stipend_trainee_view',
  components: {
    Datatable,
  },
  data() {
    return {
      moment: '' as any,
      entity_id: '' as any,
      tranche_id: '' as any,
      institute_id: '' as any,
      ids: [] as any,
      course_ids: [] as any,
      batch_ids: [] as any,
      showdetails: false,
      traineeList: [] as any,
      tableHeader: [
        {
          name: 'SL',
          key: 'sl',
          sortable: true,
        },
        {
          name: 'Association',
          key: 'association',
          sortable: true,
        },
        {
          name: 'Institute',
          key: 'institute',
          sortable: true,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: false,
        },
        {
          name: 'Start',
          key: 'start',
          sortable: false,
        },
        {
          name: 'End',
          key: 'end',
          sortable: false,
        },
        {
          name: 'Trainee',
          key: 'trainee',
          sortable: true,
        },
        {
          name: 'Reference_Number',
          key: 'ref_num',
          sortable: true,
        },
        {
          name: 'Registration Number',
          key: 'registration_number',
          sortable: true,
        },
        {
          name: 'Account type',
          key: 'account_type',
          sortable: true,
        },
        {
          name: 'Bank Detail',
          key: 'bank_detail',
          sortable: true,
        },
        {
          name: 'Account Number',
          key: 'acc_number',
          sortable: true,
        },
        {
          name: 'Father',
          key: 'father_name',
          sortable: true,
        },
        {
          name: 'Mother',
          key: 'Mother_name',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Income',
          key: 'income',
          sortable: true,
        },
        {
          name: 'Permanent Address',
          key: 'per_address',
          sortable: true,
        },
        {
          name: 'PostCode',
          key: 'postcode',
          sortable: true,
        },
        {
          name: 'Upazilla',
          key: 'upazilla',
          sortable: true,
        },
        {
          name: 'Home District',
          key: 'home_district',
          sortable: true,
        },
        {
          name: 'Attendance Percentage',
          key: 'attendance_percentage',
          sortable: true,
        },
        {
          name: 'Ethnic',
          key: 'ethnic',
          sortable: true,
        },
        {
          name: 'Physically Disabled',
          key: 'physically_disabled',
          sortable: true,
        },
        {
          name: 'Income Certificate',
          key: 'income_certificate',
          sortable: true,
        },
      ],
      start_date: '' as any,
      end_date: '' as any,
      showselectbtn: false,
      load: false,
      loading: false,
    };
  },
  async created() {
    this.moment = moment;
    this.entity_id = this.$route.params.entity_id;
    this.tranche_id = this.$route.params.tranche_id;
    this.institute_id = this.$route.params.institute_id;

    let data = window.location.search;

    let urlParams = new URLSearchParams(data);
    this.entity_id = urlParams.get('entity_id');
    this.tranche_id = urlParams.get('tranche_id');
    this.institute_id = urlParams.get('institute_id');
    this.start_date = urlParams.get('start_date');
    this.end_date = urlParams.get('end_date');

    this.getTraineeList();
  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });

      let formData = new FormData();
      formData.set('entity_info_id', this.entity_id);
      formData.set('tranche_id', this.tranche_id);
      formData.set('institute_id', this.institute_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      await ApiService.post('report/stipendtraineepdf', formData)
        .then((response) => {
          ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          ElNotification.closeAll();
          console.log(response);
        });
    },
    async printPdf() {
      let formData = new FormData();
      formData.set('entity_info_id', this.entity_id);
      formData.set('tranche_id', this.tranche_id);
      formData.set('institute_id', this.institute_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/stipendtraineeprint`,
          formData
        )
        .then((response) => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      formData.set('entity_info_id', this.entity_id);
      formData.set('tranche_id', this.tranche_id);
      formData.set('institute_id', this.institute_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/stipendtraineeexport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Institute Status list.xlsx');
          document.body.appendChild(link);
          link.click();
          ElNotification.closeAll();
        })
        .catch((response) => {
          ElNotification.closeAll();
          console.log(response);
        });
    },
    async getTraineeList() {
      // console.log(this.entity_id);
      // console.log(this.tranche_id);
      // console.log(this.institute_id);
      // console.log(this.start_date);
      // console.log(this.end_date);
      this.load = true;
      ApiService.get(
        this.VUE_APP_STIPEND_COURSE_TRAINEE_LIST_API +
          '?entity_info_id=' +
          this.entity_id +
          '&tranche_id=' +
          this.tranche_id +
          '&institute_id=' +
          this.institute_id +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
      )
        .then((response) => {
          console.log(response);
          this.traineeList = response.data.data;

          this.showselectbtn = true;
          for (let i = 0; i < this.traineeList.length; i++) {
            this.ids.push(this.traineeList[i].id);
          }
          console.log(this.ids);
          this.showdetails = true;
          this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async formSubmit() {
      this.loading = true;
      let formdata = new FormData();
      formdata.set('ids', JSON.stringify(this.ids));
      formdata.set('start_date', this.start_date);
      formdata.set('end_date', this.end_date);
      ApiService.post(this.VUE_APP_STIPEND_TRAINEE_UPDATE_API, formdata)
        .then((response) => {
          //
          this.loading = false;
          if (response.data.status === 'success') {
            Swal.fire({
              title: 'Success!',
              text: 'Update Successfully',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          } else {
            this.loading = false;
            Swal.fire({
              title: 'Submission Error',

              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
          console.log(response);
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
        });
    },
  },
  setup() {},
});
